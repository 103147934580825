import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import jQuery from "jquery";
import "bootstrap/dist/js/bootstrap.min.js";
import "./styles/app.scss";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { datadogRum } from '@datadog/browser-rum';
import {datadogLogs} from "@datadog/browser-logs";

const $ = jQuery;

window.$ = $;

datadogRum.init({
  applicationId: '6a985405-e1f2-4054-ab41-ea3b98994986',
  clientToken: 'pub06a2749364db4dc721e857ad1e9ebc83',
  site: 'datadoghq.com',
  service: 'app-eidv-io',
  env: process.env.Environment,
  sampleRate: 1,
  sessionReplaySampleRate: 0,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingOrigins: [/https:\/\/.*\.eidv\.io/, /https:\/\/.*\.ridx\.io/ ]
  });
datadogRum.startSessionReplayRecording();
datadogLogs.init({
  service: 'app-eidv-io',
  env: process.env.Environment,
  clientToken: 'pub06a2749364db4dc721e857ad1e9ebc83',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  forwardConsoleLogs: "all",
  sampleRate: 100
});

Vue.component("font-awesome-icon", FontAwesomeIcon);

import VTooltip from "v-tooltip";
Vue.use(VTooltip, {
  defaultTrigger: "click",
});

import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "The {_field_} field is required.",
});

extend("regex", {
  ...required,
  message: "The {_field_} field is invalid.",
});

Vue.component("ValidationProvider", ValidationProvider);

import VuejsDialog from "vuejs-dialog";

// include the default style
import "vuejs-dialog/dist/vuejs-dialog.min.css";

Vue.use(VuejsDialog, {
  okText: "OK",
  cancelText: "Cancel",
});

$(document).click(function(e) {
  if (!$(e.target).is(".panel-body")) {
    $(".collapse").collapse("hide");
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
