<template>
    <div class="row ">
        <div class="col-12">
            <h2>CONSENT STATEMENT</h2>
            <p>
                You can use your phone to verify your identity without having to visit any physical location. In order to collect details of your ID documents, we require your consent. Identity data will be collected by H&R Block and will be used to match against the records of a third party ID document service provider. By clicking “Accept”, you agree to provide your personal details and consent to your information being checked with the document issuer or official record holder via third party systems solely for the purpose of confirming your identity. Your personal details will not be used or shared for any other purpose than confirming your identity. Without your acceptance of this declaration, you will need to verify your identity through another method and will therefore be required to contact H&R Block directly.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TermsConditions'
    }
</script>
