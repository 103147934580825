<template>
    <div class="row">

        <div class="col-12">

            <div class="progress">
                <div class="progress-bar" role="progressbar" :style="{width: '50%'}"></div>
            </div>

            <div class="d-flex flex-column justify-content-center h-100 step2">

                <action :action-type="formKey"></action>

            </div>
        </div>
    </div>
</template>

<script>
    import Action from "../Verification/Action";

    export default {
        name: "Form",
        props: ['formKey'],
        components: {Action},
        mounted() {

        }
    }
</script>

<style lang="scss" scoped>
    .progress {
        height: 5px;
    }

    .step1 {
        h1 {
            margin-bottom: 3em;
        }
    }
</style>
