import axios from 'axios';

class RapidIdClient {
    constructor() {
        let service = axios.create({
            headers: {accept: 'application/json'},
            baseURL: process.env.VUE_APP_API_URI
        });
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        return Promise.reject(error);
    }

    action(path, payload, callback) {
        return this.service.request({
            method: 'POST',
            url: path,
            responseType: 'json',
            data: payload
        })
            .then((response) => callback(response.status, response.data))
            .catch(error => callback(error.response.status, error.response.data));
    }
}

export default RapidIdClient;
