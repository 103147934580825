import {getField, updateField} from "vuex-map-fields";
import RapidIdClient from "../../RapidIdClient";
import rapidid_actions from "../../services/RapidId/Actions";
import router from "../../router";

const state = {
    wizardStep: 0,
    maxSteps: 2,
    documentType: null,
    dateOfBirth: null,
    givenName: null,
    middleName: null,
    familyName: null,
    documentNumber: null,
    errors: null,
    token: null,
    rapid_id: null,
    userAccountAL: null,
    userAccountID: null,
    userAccountFN: null
};

const getters = {
    getCurrentStep: (state) => state.wizardStep,
    getDocumentType: (state) => state.documentType,
    getMaxSteps: (state) => state.maxSteps,
    getErrors: (state) => state.errors,
    getToken: (state) => state.token,
    getRapidId: (state) => state.rapid_id,
    getUserAccountAL: (state) => state.userAccountAL,
    getUserAccountID: (state) => state.userAccountID,
    getUserAccountFN: (state) => state.userAccountFN,
    getField,
};

const mutations = {
    SET_WIZARD_STEP(state, data) {
        state.wizardStep = data;
    },
    SET_ERRORS(state, data) {
        state.errors = data;
    },
    SET_DOCUMENT_TYPE(state, data) {
        state.documentType = data;
    },
    SET_TOKEN_VALID(state, data) {
        state.token = data;
    },
    SET_RAPID_ID(state, data) {
        state.rapid_id = data;
    },
    updateField,
    SET_USER_ACCOUNT_AL(state, data) {
        state.userAccountAL = data;
    },
    SET_USER_ACCOUNT_ID(state, data) {
        state.userAccountID = data;
    },
    SET_USER_ACCOUNT_FN(state, data) {
        state.userAccountFN = data;
    }
};

const actions = {
    submit({commit, getters}, payload) {
        if (!(payload.type in rapidid_actions.actions)) {
            console.error(`Bad type: "${payload.type}"`);
            return;
        }

        let fields = {...payload.fields};

        let data = {};

        for (let field in fields) {
            let fieldName = (payload.fields[field].name !== undefined) ? payload.fields[field].name : field;
            if (data[fieldName] === undefined && payload.fields[field].value !== undefined && payload.fields[field].value !== null) {
                data[fieldName] = payload.fields[field].value;
            }
        }

        let path = rapidid_actions.actions[payload.type].path;

        data['userAccountAL'] = getters.getUserAccountAL; //userAccountAL
        data['userAccountFN'] = getters.getUserAccountFN;
        data['userAccountID'] = getters.getUserAccountID;

        let client = new RapidIdClient();
        client.action(path, data, (status, data) => {
            if (status !== 200) {
                console.error(status, data);

                if (status === 422) {
                    commit("SET_ERRORS", data.errors);
                }

                if (status === 400) {
                    alert(data.data);
                }

                return;
            }

            for (let field in payload.fields) {
                payload.fields[field].value = null;
            }

            commit("SET_WIZARD_STEP", 0);
            commit("SET_RAPID_ID", data.data.rapidID);

            router.push({name: "Step 4 - Summary"});
        });
    },

    checkToken({commit}, mobileIdTrackingNumber) {
        let path = "validator/checkinfotoken";
        if (mobileIdTrackingNumber === undefined) {
            return new Promise((resolve, reject) => {
                reject('notoken')
            });
        }

        let client = new RapidIdClient();
        return client.action(path, {mobileIdTrackingNumber}, (status, data) => {
            if (status === 400) {
                return new Promise((resolve, reject) => {
                    reject('used')
                });
            }
            if (status === 404) {
                return new Promise((resolve, reject) => {
                    reject('notoken')
                });
            }

            commit("SET_TOKEN_VALID", mobileIdTrackingNumber);
            commit("SET_USER_ACCOUNT_AL", data.data.userAccountAL);
            commit("SET_USER_ACCOUNT_FN", data.data.userAccountFN);
            commit("SET_USER_ACCOUNT_ID", data.data.userAccountID);

            return new Promise((resolve) => {
                resolve(true)
            });
        });
    },

    checkComplete({getters}) {
        let path = "validator/checkcomplete";

        const payload = {
            completedCheckRapidID: getters.getRapidId,
            mobileIdTrackingNumber: getters.getToken,
            userAccountAL: getters.getUserAccountAL,
            userAccountFN: getters.getUserAccountFN,
            userAccountID: getters.getUserAccountID
        };

        let client = new RapidIdClient();
        client.action(path, payload, (status, data) => {
            if (status !== 200) {
                console.error(status, data);
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
