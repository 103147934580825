import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ErrorPage from "../views/ErrorPage.vue";
import VerificationWizard from "../views/VerificationWizard";
import TermsConditions from "../views/TermsConditions";
import TermsConditionsStep from "../views/TermsConditionsStep";
import Form from "../views/Verification/Form";
import Summary from "../views/Verification/Summary";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and Conditions",
    component: TermsConditions,
  },
  {
    path: "/step1/terms-and-conditions",
    name: "Step 1 - Terms and Conditions",
    component: TermsConditionsStep,
  },
  {
    path: "/step2/verify",
    name: "Step 2 - Verification Wizard",
    component: VerificationWizard,
  },
  {
    path: "/step3/form/:formKey",
    name: "Step 3 - Fill the form",
    component: Form,
    props: true,
  },
  {
    path: "/step4/summary",
    name: "Step 4 - Summary",
    component: Summary,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
