import FieldType from '../../enums/FieldType';

export default {
    actions: {
        'drivers-licence': {
            name: 'Drivers licence',
            path: 'validator/drivers-licence',
            fields: {
                birth_date: {
                    type: FieldType.DATE,
                    label: 'Birth Date',
                    help: 'Partial Dates of Birth are not accepted',
                    rules: 'required'
                },
                given_name: {
                    type: FieldType.TEXT,
                    label: 'Given Name',
                    help: 'If you have a single name, enter it into the Family Name field and enter a fullstop \'.\' into the Given Name/s field.',
                    rules: 'required'
                },
                middle_name: {
                    type: FieldType.TEXT,
                    label: 'Middle Name',
                    help: 'Middle Name/s is an optional field, leave the field blank if you don’t want to send it. If you provide a Middle Name/s value it will be checked by the Issuer.',
                    optional: true
                },
                family_name: {
                    type: FieldType.TEXT,
                    label: 'Family Name',
                    help: 'If you have a single name, enter it into the Family Name field and enter a fullstop \'.\' into the Given Name/s field.',
                    rules: 'required'
                },
                licence_number: {
                    type: FieldType.TEXT,
                    label: 'Licence Number',
                    help: 'The licence number format is alpha-numeric up to 10 characters.',
                    rules: 'required'
                },
                card_number: {
                    type:FieldType.TEXT,
                    label: "Card Number",
                    help: 'The card number format is alpha-numeric up to 10 characters.',
                    rules: 'required'
                },
                state_of_issue: {
                    type: FieldType.SELECT_STATE,
                    label: 'Registration State',
                    required: true,
                    help: 'State of document registration',
                    rules: 'required'
                }
            },
            leadField: 'state_of_issue'
        },
        'passport': {
            name: 'Passport',
            path: 'validator/passport',
            fields: {
                expiry_date: {
                    type: FieldType.DATE,
                    label: 'Expiry date',
                    help: 'To verify your Travel Document it must not have expired more than 3 years ago.',
                    optional: true
                },
                travel_document_number: {
                    type: FieldType.TEXT,
                    label: 'Travel Document Number',
                    help: 'The format for Travel Document Number is 1 alpha character, followed by 7 numeric characters or 2 alpha characters followed by 7 numeric characters.',
                    rules: 'required'
                },
                family_name: {
                    type: FieldType.TEXT,
                    label: 'Family Name',
                    help: 'If you have a single name, enter it into the Family Name field and leave the Given Name/s field blank',
                    rules: 'required'
                },
                given_name: {
                    type: FieldType.TEXT,
                    label: 'Given Name/s',
                    help: 'All Given and Middle names, as printed on the Travel Document must be provided. If you have a single name, enter it into the Family Name field and leave the Given Name/s field blank',
                    rules: 'required'
                },
                gender: {
                    type: FieldType.SELECT,
                    label: 'Gender',
                    options: {
                        'M': 'Male',
                        'F': 'Female',
                        'X': 'Neutral'
                    },
                    help: 'This field must be \'M\', \'F\', \'X\'',
                    rules: 'required'
                },
                birth_date: {
                    type: FieldType.DATE,
                    label: 'Date of Birth',
                    help: 'If you have a partial Date of Birth leave the day and/or month field blank',
                    rules: 'required'
                },
            }
        },
        'medicare': {
            name: 'Medicare',
            path: 'validator/medicare',
            fields: {
                birth_date: {
                    type: FieldType.DATE,
                    label: 'Date of Birth',
                    help: 'Date of Birth is an optional field, if you provide a Date of Birth value it will be checked by the Issuer. Partial Dates of Birth are not accepted',
                    optional: true
                },
                card_number: {
                    type: FieldType.TEXT,
                    label: 'Medicare Card Number',
                    help: 'Medicare Card number format is 10 numeric characters without spaces',
                    rules: 'required'
                },
                card_type: {
                    type: FieldType.SELECT,
                    label: 'Medicare Card Types',
                    options: {
                        'Y': 'Yellow',
                        'G': 'Green',
                        'B': 'Blue'
                    },
                    help: 'Medicare Card Types',
                    rules: 'required'
                },
                multiline: {
                    type: FieldType.CHECKBOX,
                    label: 'Does your name appear across more than one line of the card?'
                },
                full_name1: {
                    type: FieldType.TEXT,
                    label: 'Name',
                    help: 'Enter your Name exactly as it apperas on your Medicare card. If your name spans more than one line on the card, enter the values as they appear on the card in the corresponding Name line fields.',
                    rules: 'required',
                    placeholder: 'Line 1'
                },
                full_name2: {
                    type: FieldType.TEXT,
                    label: null,
                    placeholder: 'Line 2',
                    show_if: {
                        multiline: true
                    }
                },
                full_name3: {
                    type: FieldType.TEXT,
                    label: null,
                    placeholder: 'Line 3',
                    show_if: {
                        multiline: true
                    }
                },
                full_name4: {
                    type: FieldType.TEXT,
                    label: null,
                    placeholder: 'Line 4',
                    show_if: {
                        multiline: true
                    }
                },
                individual_reference_number: {
                    type: FieldType.TEXT,
                    label: 'Individual Reference Number',
                    help: 'Individual Reference Number is 1 numeric character that relates to the position of the individual on the card'
                },
                card_expiry: {
                    type: FieldType.DATE,
                    label: 'Expiry Date',
                    help: 'For Yellow and Blue Medicare cards a full date is required including the day, month and year',
                    show_if: {
                        card_type: ['B', 'Y']
                    }
                },
                card_expiry_ym: {
                    type: FieldType.DATE_YM,
                    label: 'Expiry Date',
                    help: 'For Green Medicare cards the expiry date only requires a month and year value',
                    show_if: {
                        card_type: ['G']
                    },
                    name: 'card_expiry'
                }
            },
            leadField: 'card_type'
        },
        'birth-certificate': {
            name: 'Birth certificate',
            path: 'validator/birth-certificate',
            leadField: 'registration_state',
            fields: {
                family_name: {
                    type: FieldType.TEXT,
                    label: 'Family Name',
                    rules: 'required',
                    help: 'If you have a single name, enter it as it appears on certificate.'
                },
                given_name: {
                    type: FieldType.TEXT,
                    label: 'Given Name/s',
                    help: 'All Given and Middle names, as printed on the certificate must be provided. If you have a single name, enter it as it appears on certificate.',
                    rules: 'required'
                },
                birth_date: {
                    type: FieldType.DATE,
                    label: 'Date of Birth',
                    rules: 'required',
                    help: 'Partial Dates of Birth are not accepted'
                },
                registration_state: {
                    type: FieldType.SELECT_STATE,
                    label: 'Registration State',
                    help: 'State of document registration',
                    rules: 'required'
                },
                registration_date: {
                    type: FieldType.DATE,
                    label: 'Registration date',
                    show_if: {
                        registration_state: ['QLD', 'TAS']
                    },
                    help: 'Either the Certificates Number or Registration Date is required. To provide a more reliable check, the Certificate Number should be entered in favour of the Registration Date, where available. Registration Date must be a full date, on or after the Date of Birth.'
                },
                registration_year: {
                    type: FieldType.YEAR,
                    label: 'Registration year',
                    show_if: {
                        registration_state: ['VIC']
                    },
                    help: 'Registration Year input must be on or after the Date of Birth year value supplied'
                },
                registration_number: {
                    type: FieldType.TEXT,
                    label: 'Registration Number',
                    show_if: {
                        registration_state: ['NSW', 'ACT', 'NT', 'SA', 'WA', 'VIC']
                    },
                    help: 'Either the Registration Number or Certificate Number is required. To provide a more reliable check, the Certificate Number should be entered in favour of the Registration Number, where available. Registration Number format is up to 7 numeric characters.'
                },
                certificate_number: {
                    type: FieldType.TEXT,
                    label: 'Certificate Number',
                    help: 'Either the Registration Number or Certificate Number is required. To provide a more reliable check, the Certificate Number should be entered in favour of the Registration Number, where available. Certificate Number format is up to 11 numeric characters.'
                }
            }
        },
        'visa': {
            name: 'Visa',
            path: 'validator/visa',
            fields: {
                country_of_issue: {
                    type: FieldType.SELECT_COUNTRY,
                    label: 'Visa Country',
                    help: 'Visa Country of Origin',
                    rules: 'required'
                },
                passport_number: {
                    type: FieldType.TEXT,
                    label: 'Travel Document Number',
                    help: 'Enter your Travel Document Number from the passport that you used to apply for your Australian Visa.',
                    rules: 'required'
                },
                family_name: {
                    type: FieldType.TEXT,
                    label: 'Family Name/s',
                    help: 'If you have a single name, enter it into the Family Name field and enter a hyphen \'-\' into the Given Name field.',
                    rules: 'required'
                },
                given_name: {
                    type: FieldType.TEXT,
                    label: 'Given Name',
                    help: 'All Given and Middle names, as printed on the Travel Document must be provided. If you have a single name, enter it into the Family Name field and enter a hyphen \'-\' into the Given Name/s field.',
                    rules: 'required'
                },
                birth_date: {
                    type: FieldType.DATE,
                    label: 'Date of Birth',
                    help: 'If you have a partial Date of Birth leave the day and/or month field blank',
                    rules: 'required'
                }
            }
        },
        'name-change': {
            name: 'Change Of Name Certificate',
            path: 'validator/name-change',
            leadField: 'registration_state',
            fields: {
                family_name: {
                    type: FieldType.TEXT,
                    label: 'New Family Name',
                    help: 'If you have a single name, enter it as it appears on certificate',
                    rules: 'required'
                },
                given_name: {
                    type: FieldType.TEXT,
                    label: 'New Given Name/s',
                    help: 'All Given and Middle names, as printed on the certificate must be provided. If you have a single name, enter it as it appears on certificate.',
                    rules: 'required'
                },
                birth_date: {
                    type: FieldType.DATE,
                    label: 'Date of Birth',
                    rules: 'required',
                    help: 'Partial Dates of Birth are not accepted'
                },
                registration_state: {
                    type: FieldType.SELECT_STATE,
                    label: 'Registration State',
                    help: 'State of document registration',
                    rules: 'required'
                },
                registration_date: {
                    type: FieldType.DATE,
                    label: 'Registration date',
                    show_if: {
                        registration_state: ['QLD']
                    },
                    help: 'Either the Registration Date or Certificate Number is required. To provide a more reliable check, the Certificate Number should be entered in favour of the Registration Date, where available. Registration Date must be a full date, on or after the Date of Birth.'
                },
                registration_year: {
                    type: FieldType.YEAR,
                    label: 'Registration year',
                    show_if: {
                        registration_state: ['VIC']
                    },
                    help: 'Registration Year input must be on or after the Date of Birth year value supplied'
                },
                registration_number: {
                    type: FieldType.TEXT,
                    label: 'Registration Number',
                    show_if: {
                        registration_state: ['NSW', 'ACT', 'NT', 'SA', 'WA', 'VIC']
                    },
                    help: 'Either the Registration Number or Certificate Number is required. To provide a more reliable check, the Certificate Number should be entered in favour of the Registration Number, where available. The Registration Number format is up to 6 numeric characters. If your Registration Number includes an alpha character, enter the numeric values only.'
                },
                certificate_number: {
                    type: FieldType.TEXT,
                    label: 'Certificate Number',
                    help: 'Either the Registration Date or Certificate Number is required. To provide a more reliable check, the Certificate Number should be entered in favour of the Registration Date, where available. Certificate Number format is 10 numeric characters.'
                }
            }
        },
        'marriage': {
            name: 'Marriage Certificate',
            path: 'validator/marriage',
            leadField: 'registration_state',
            fields: {
                registration_state: {
                    type: FieldType.SELECT_STATE,
                    label: 'Registration State',
                    help: 'State of document registration',
                    rules: 'required'
                },
                family_name: {
                    type: FieldType.TEXT,
                    label: 'Family Name (Groom)',
                    help: 'Single Name certificates are not verifiable via the DVS.',
                    rules: 'required'
                },
                given_name: {
                    type: FieldType.TEXT,
                    label: 'Given Name/s (Groom)',
                    help: 'All Given and Middle names, as printed on the certificate must be provided. Single Name certificates are not verifiable via the DVS.',
                    rules: 'required'
                },
                family_name2: {
                    type: FieldType.TEXT,
                    label: 'Family Name (Bride)',
                    help: 'Single Name certificates are not verifiable via the DVS.',
                    rules: 'required'
                },
                given_name2: {
                    type: FieldType.TEXT,
                    label: 'Given Name/s (Bride)',
                    help: 'All Given and Middle names, as printed on the certificate must be provided. Single Name certificates are not verifiable via the DVS.',
                    rules: 'required'
                },
                event_date: {
                    type: FieldType.DATE,
                    label: 'Date of Event',
                    rules: 'required',
                    help: 'Partial Dates of Event are not accepted'
                },
                registration_date: {
                    type: FieldType.DATE,
                    label: 'Registration date',
                    show_if: {
                        registration_state: ['QLD']
                    },
                    help: 'Either the Registration Date or Certificate Number is required. To provide a more reliable check, the Certificate Number should be entered in favour of the Registration Date, where available. Registration Date must be a full date, on or after the Date of Event.'
                },
                registration_year: {
                    type: FieldType.YEAR,
                    label: 'Registration year',
                    show_if: {
                        registration_state: ['VIC']
                    },
                    help: 'Registration Year input must be on or after the Date of Birth year value supplied'
                },
                registration_number: {
                    type: FieldType.TEXT,
                    label: 'Registration Number',
                    show_if: {
                        registration_state: ['NSW', 'ACT', 'NT', 'SA', 'WA', 'VIC']
                    },
                    help: 'Either the Registration Number or Certificate Number is required. To provide a more reliable check, the Certificate Number should be entered in favour of the Registration Number, where available. Registration Number format is up to 7 numeric characters.'
                },
                certificate_number: {
                    type: FieldType.TEXT,
                    label: 'Certificate Number',
                    help: 'Either the Registration Date or Certificate Number is required. To provide a more reliable check, the Certificate Number should be entered in favour of the Registration Date, where available. Certificate Number format is 10 numeric characters.'
                }
            }
        },
        'citizenship': {
            name: 'Citizenship',
            path: 'validator/citizenship',
            fields: {
                family_name: {
                    type: FieldType.TEXT,
                    label: 'Family Name',
                    rules: 'required',
                    help: 'If you have a single name, enter it into the Family Name field and leave the Given Name/s field blank'
                },
                given_name: {
                    type: FieldType.TEXT,
                    label: 'Given Name/s',
                    help: 'All Given and Middle names, as printed on the certificate must be provided. If you have a single name, enter it into the Family Name field and leave the Given Name/s field blank',
                    rules: 'required'
                },
                birth_date: {
                    type: FieldType.DATE,
                    label: 'Date of Birth',
                    rules: 'required',
                    help: 'If you have a partial Date of Birth leave the day and/or month field blank.'
                },
                acquisition_date: {
                    type: FieldType.DATE,
                    label: 'Acquisition Date',
                    rules: 'required',
                    help: 'Partial Dates are not accepted'
                },
                stock_number: {
                    type: FieldType.TEXT,
                    label: 'Stock Number',
                    help: 'The Stock Number is printed on the back top right hand corner of citizenship certificates, typically in the format of 1-3 alpha characters, a number in brackets, followed by numeric characters. Special characters and spaces should be removed from the number and it should be entered into the Stock Number field. Example: ACC (1) 23456 should be entered as ACC123456.',
                    rules: 'required'
                }
            }
        },
        'descent': {
            name: 'Descent Certificate',
            path: 'validator/descent',
            fields: {
                family_name: {
                    type: FieldType.TEXT,
                    label: 'Family Name',
                    rules: 'required',
                    help: 'If you have a single name, enter it into the Family Name field and leave the Given Name/s field blank'
                },
                given_name: {
                    type: FieldType.TEXT,
                    label: 'Given Name/s',
                    help: 'All Given and Middle names, as printed on the certificate must be provided. If you have a single name, enter it into the Family Name field and leave the Given Name/s field blank',
                    rules: 'required'
                },
                acquisition_date: {
                    type: FieldType.DATE,
                    label: 'Acquisition Date',
                    rules: 'required',
                    help: 'Partial Dates are not accepted'
                },
                birth_date: {
                    type: FieldType.DATE,
                    label: 'Date of Birth',
                    rules: 'required',
                    help: 'If you have a partial Date of Birth leave the day and/or month field blank.'
                },
                stock_number: {
                    type: FieldType.TEXT,
                    label: 'Stock Number',
                    help: 'The Stock Number is printed on the back top right hand corner of citizenship certificates, typically in the format of 1-3 alpha characters, a number in brackets, followed by numeric characters. Special characters and spaces should be removed from the number and it should be entered into the Stock Number field. Example: ACC (1) 23456 should be entered as ACC123456.',
                    rules: 'required'
                }
            }
        },
        'centrelink': {
            name: 'Centrelink Card',
            path: 'validator/centrelink',
            fields: {
                card_type: {
                    type: FieldType.SELECT,
                    label: 'Centrelink Card Type',
                    options: {
                        'HCC': 'Health Care Card (HCC)',
                        'PCC': 'Pensioner Concession Card (PCC)',
                        'SHC': 'Senior Health Card (SHC)'
                    },
                    help: 'Centrelink Card Type',
                    rules: 'required'
                },
                birth_date: {
                    type: FieldType.DATE,
                    label: 'Date of Birth',
                    help: 'Date of Birth is an optional field, if you provide a Date of Birth value it will be checked by the Issuer. Partial Dates of Birth are not accepted'
                },
                name: {
                    type: FieldType.TEXT,
                    label: 'Name',
                    help: 'Enter your Name exactly as it appears on your Centrelink card.',
                    rules: 'required'
                },
                crn: {
                    type: FieldType.TEXT,
                    label: 'CRN',
                    help: 'CRN format is 9 numeric characters followed by alphanumeric character without spaces.',
                    rules: 'required'
                },
                card_expiry: {
                    type: FieldType.DATE,
                    label: 'Expiry Date',
                    help: 'A full expiry date is required including the day, month and year.',
                },
            }
        },
        'immicard': {
            name: 'Immigration Card',
            path: 'validator/immicard',
            fields: {
                family_name: {
                    type: FieldType.TEXT,
                    label: 'Family Name',
                    rules: 'required',
                    help: 'If you have a single name, enter it into the Family Name field and enter a hyphen \'-\' into the Given Name field.'
                },
                given_name: {
                    type: FieldType.TEXT,
                    label: 'Given Name/s',
                    help: 'If you have a single name, enter it into the Family Name field and enter a hyphen \'-\' into the Given Name/s field.',
                    rules: 'required'
                },
                birth_date: {
                    type: FieldType.DATE,
                    label: 'Date of Birth',
                    rules: 'required',
                    help: 'If you have a partial Date of Birth leave the day and/or month field blank.'
                },
                immi_card_number: {
                    type: FieldType.TEXT,
                    label: 'ImmiCard Number',
                    help: 'The ImmiCard Number format is 3 alpha characters followed by 6 numeric characters. The Alpha characters have been prepopualated for you based on the card type you have selected.',
                    rules: 'required'
                }
            }
        }
    }
}
