<template>
    <ValidationObserver ref="form" tag="form" @submit.prevent="onSubmit">
        <h1 class="mb-4">{{ actionName }}</h1>
        <div class="form-group" v-for="(field, fieldKey) in fields" v-bind:key="'field-'+fieldKey">
            <template v-if="!!showField(fieldKey) && !!showIf(fieldKey)">
                <label v-if="field.label">
                    {{ field.label }}:
                    <span class="text-info" v-if="field.optional !== undefined && field.optional === true">(Optional)</span>
                    <font-awesome-icon v-if="field.help !== undefined" icon="question-circle" v-tooltip="field.help" class="ml-1"/>
                </label>

                <TextField v-if="['text'].includes(field.type)" :field="field" :field-name="fieldKey" :value.sync="fields[fieldKey].value"/>

                <CheckboxField v-if="['checkbox'].includes(field.type)" :field="field" :field-name="fieldKey" :value.sync="fields[fieldKey].value"/>

                <DateYMDField v-if="['date'].includes(field.type)" :field="field" :field-name="fieldKey" :value.sync="fields[fieldKey].value"/>

                <DateYMField v-if="['date-ym'].includes(field.type)" :field="field" :field-name="fieldKey" :value.sync="fields[fieldKey].value"/>

                <YearField v-if="['year'].includes(field.type)" :field="field" :field-name="fieldKey" :value.sync="fields[fieldKey].value"/>

                <SelectField v-if="field.type === 'select'" :field="field" :field-name="fieldKey" :value.sync="fields[fieldKey].value"/>

                <SelectStateField v-if="field.type === 'select-state'" :field="field" :field-name="fieldKey" :value.sync="fields[fieldKey].value"/>

                <SelectCountryField v-if="field.type === 'select-country'" :field="field" :field-name="fieldKey" :value.sync="fields[fieldKey].value"/>

            </template>
        </div>
        <div class="d-flex justify-content-between">
            <button class="btn text-uppercase" @click.prevent="goBack">Go back...</button>
            <button v-if="!!showSubmit()" type="submit" class="btn btn-primary text-uppercase">Submit</button>
            <button v-else type="submit" class="btn btn-primary text-uppercase" @click.prevent="updateLeadValue">Continue</button>
        </div>
        <p v-if="!!showSubmit()" class="text-justify mt-2">
            By clicking submit button I confirm that I am authorised to provide the personal details presented and I consent to my information being
            checked with the document issuer or official record holder via third party systems for the purpose of confirming my identity.
        </p>
    </ValidationObserver>
</template>

<script>
    import {mapActions} from "vuex";
    import rapidid_actions from '../../services/RapidId/Actions';
    import TextField from "./Fields/TextField";
    import SelectField from "./Fields/SelectField";
    import DateYMDField from "./Fields/DateYMDField";
    import DateYMField from "./Fields/DateYMField";
    import SelectStateField from "./Fields/SelectStateField";
    import CheckboxField from "./Fields/CheckboxField";
    import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {ValidationObserver} from 'vee-validate'
    import YearField from "./Fields/YearField";
    import SelectCountryField from "./Fields/SelectCountryField";


    library.add(faQuestionCircle);

    export default {
        components: {SelectCountryField, YearField, SelectStateField, DateYMDField, DateYMField, SelectField, TextField, CheckboxField, ValidationObserver},
        props: ['actionType'],
        name: "Action",
        data() {
            return {
                fields: {},
                actionName: '',
                leadField: null,
                leadValue: null,
                trackingNumber: this.$store.getters["verificationWizard/getToken"]
            }
        },
        methods: {
            ...mapActions({
                onSubmitButtonClick: 'verificationWizard/submit'
            }),
            async onSubmit() {
                const output = await this.validateCheckTokenUsage().then((res) => { // validating the check token if check was already taken
                    if(res) {
                        return true;
                    } else {
                        return false;
                    }    
                });
                if(output === "undefined" || !output) { // Check already taken and display alert dialog 
                    this.$dialog
                        .alert('This token has already been used. Please contact H&R block for new token.')
                } else {
                    this.$refs.form.validate().then(success => {
                    if (!success) {
                        return;
                    }
                    this.$dialog
                        .confirm('Please confirm to send your form.')
                        .then(() => this.$store.dispatch('verificationWizard/submit', {type: this.actionType, fields: this.fields}))
                     });
                }
            },
            showField(fieldKey) {
                return (this.leadField !== null && (
                    (this.leadValue === null && fieldKey === this.leadField)
                    || (this.leadValue !== null && fieldKey !== this.leadField)
                )) || this.leadField === null;
            },
            showIf(fieldKey) {
                let showIf = this.fields[fieldKey].show_if;
                if (showIf === undefined) {
                    return true;
                }

                let show = false;

                for (let field in showIf) {
                    let values = showIf[field];
                    if (Array.isArray(values) && values.includes(this.fields[field].value)) {
                        show = true;
                    } else if (values === this.fields[field].value) {
                        show = true;
                    } else {
                        show = false;
                    }
                }
                return show;
            },
            showSubmit() {
                return (this.leadField !== null && this.leadValue !== null) || this.leadField === null;
            },
            goBack() {
                if (this.leadField !== null && this.leadValue !== null) {
                    this.leadValue = null;
                } else {
                    this.$router.back();
                }
            },
            updateLeadValue() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return;
                    }
                    this.leadValue = this.fields[this.leadField].value ?? null;
                });
            },
            async validateCheckTokenUsage() {
                
                return await this.$store.dispatch("verificationWizard/checkToken", this.trackingNumber)
                    .then(function success() {
                        return true;
                    })
                    .catch(function error() {
                        return false;
                    }) 
            }
        },
        created() {
            if (!(this.actionType in rapidid_actions.actions)) {
                console.error(`Bad action: "${this.actionType}"`);
                this.$router.push('/');
                return;
            }

            this.fields = rapidid_actions.actions[this.actionType].fields;
            this.actionName = rapidid_actions.actions[this.actionType].name;

            this.leadField = rapidid_actions.actions[this.actionType].leadField ?? null;
        }
    }
</script>

<style scoped>

</style>
