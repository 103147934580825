<template>
    <div class="page-container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
            <div class="navbar-brand">
                <router-link :to="{name: 'Home'}">
                    <img class="logo" src="./assets/img/rapidid-logo.svg" alt="Rapid ID" />
                </router-link>
            </div>
        </nav>

        <div class="container">
            <router-view></router-view>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
    name: "Overlap",
    data: () => ({
        menuVisible: false
    })
};
</script>
