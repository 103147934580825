<template>
    <div class="row">

        <div class="col-12">

            <div class="progress">
                <div class="progress-bar" role="progressbar" :style="{width: progressPercent}"></div>
            </div>

            <div v-if="currentStep === 0" class="d-flex flex-column justify-content-center h-100 step1 text-center">
                <h1>To verify your identity, please select a document</h1>
                <div>
                    <router-link v-for="(validator, index) in validators[currentValidators]"
                                 v-bind:key="`form-${index}`"
                                 :to="{name: 'Step 3 - Fill the form', params: {formKey: validator.key}}"
                                 class="w-100 btn btn-lg btn-outline-primary mb-2 text-uppercase">{{ validator.name }}
                    </router-link>

                    <button class="w-100 btn btn-lg btn-outline-secondary mb-2 mt-5 text-uppercase"
                            @click.prevent="currentValidators = (currentValidators === 'other') ? 'primary' : 'other'">
                        {{ currentValidators === 'primary' ? 'Other...' : 'Go back...' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapActions, mapGetters} from 'vuex';
    import {mapFields} from 'vuex-map-fields';

    export default {
        name: 'verificationWizard',
        data() {
            return {
                currentValidators: 'primary',
                validators: {
                    primary: [
                        {
                            name: 'Passport',
                            key: 'passport'
                        },
                        {
                            name: 'Drivers Licence',
                            key: 'drivers-licence'
                        },
                        {
                            name: 'Medicare',
                            key: 'medicare'
                        }
                    ],
                    other: [
                        {
                            name: 'Birth certificate',
                            key: 'birth-certificate'
                        },
                        {
                            name: 'Visa',
                            key: 'visa'
                        },
                        {
                            name: 'Change Of Name Certificate',
                            key: 'name-change'
                        },
                        {
                            name: 'Marriage Certificate',
                            key: 'marriage'
                        },
                        {
                            name: 'Citizenship',
                            key: 'citizenship'
                        },
                        {
                            name: 'Descent',
                            key: 'descent'
                        },
                        {
                            name: 'Centrelink Card',
                            key: 'centrelink'
                        },
                        {
                            name: 'Immigration Card',
                            key: 'immicard'
                        }
                    ]
                }
            }
        },
        computed: {
            ...mapGetters({
                currentStep: 'verificationWizard/getCurrentStep',
                maxSteps: 'verificationWizard/getMaxSteps',
                documentType: 'verificationWizard/getDocumentType',
            }),
            ...mapFields('verificationWizard', ['dateOfBirth', 'givenName', 'middleName', 'familyName', 'documentNumber']),
            progressPercent() {
                let percent = this.currentStep / this.maxSteps * 100;
                return percent + '%';
            }
        },
        methods: {
            ...mapActions({
                onSubmitButtonClick: 'verificationWizard/submit'
            }),
        }
    }
</script>

<style lang="scss" scoped>
    .progress {
        height: 5px;
    }

    .step1 {
        h1 {
            margin-bottom: 3em;
        }
    }
</style>
