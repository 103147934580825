<template>
    <div class="row">
        <div class="col-12">
            <div class="progress">
                <div class="progress-bar" role="progressbar" :style="{width: '100%'}"></div>
            </div>

            <div class="d-flex flex-column justify-content-center h-100 step2">
                <h1 class="mb-4">Thank you. Your check has been completed.</h1>

                <p>Your verification will now be reviewed and you will be made aware of the result of your identity check soon.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Summary",
    mounted() {
        console.log("summary");
        this.$store.dispatch("verificationWizard/checkComplete");
    }
};
</script>

<style scoped>
.progress {
    height: 5px;
}
</style>
