<template>
    <div>
        <div class="d-flex">
            <ValidationProvider :rules="fieldRulesMonth()" v-slot="{ errors }" name="Month" tag="div">
                <input type="number" class="form-control" v-model="valueVal.month" placeholder="MM"/>
                <span class="small text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider :rules="fieldRulesYear()" v-slot="{ errors }" name="Year" tag="div">
                <input type="number" class="form-control" v-model="valueVal.year" placeholder="YYYY"/>
                <span class="small text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
        <span class="small text-danger" v-if="backendErrors">{{ backendErrors }}</span>
    </div>
</template>

<script>
    import {extend} from 'vee-validate';
    import {min_value, max_value} from 'vee-validate/dist/rules';

    extend('min_value', min_value);
    extend('max_value', max_value);

    export default {
        name: "DateYMField",
        props: ['field', 'value', 'fieldName'],
        data() {
            return {
                valueVal: {
                    year: '',
                    month: ''
                }
            }
        },
        watch: {
            valueVal: {
                deep: true,
                handler() {
                    this.updateValue();
                }
            },
        },
        mounted() {
            if (this.value === undefined) {
                return;
            }
            if (this.value === null) {
                return;
            }
            let value = this.value.split('-');
            if (value.length === 2) {
                this.valueVal.year = value[0];
                this.valueVal.month = value[1];
            }
        },
        methods: {
            updateValue: function () {
                let value = ('0' + this.valueVal.year).slice(-4) + '-' + ('0' + this.valueVal.month).slice(-2);
                let timestamp = Date.parse(value);

                if (timestamp) {
                    this.$emit('update:value', value);
                }
            },
            fieldRulesMonth: function () {
                let rules = 'min_value:1|max_value:12';
                if (this.field.rules !== undefined) {
                    rules += '|' + this.field.rules;
                }

                return rules;
            },
            fieldRulesYear: function () {
                let rules = 'min_value:1900|max_value:2100';
                if (this.field.rules !== undefined) {
                    rules += '|' + this.field.rules;
                }

                return rules;
            }
        },
        computed: {
            backendErrors() {
                let errors = this.$store.getters["verificationWizard/getErrors"];
                if (errors !== null && errors[this.fieldName] !== undefined) {
                    return errors[this.fieldName][0];
                }

                return null;
            }
        }
    }
</script>

<style scoped>

</style>
