<template>
    <ValidationProvider :rules="field.rules" v-slot="{ errors }" :name="field.label">
        <select class="form-control" v-model="valueVal">
            <option valye=""></option>
            <option v-for="(option, optionKey) in options" v-bind:key="'option-'+optionKey" :value="optionKey">{{ optionKey }}
            </option>
        </select>
        <span class="small text-danger" v-if="backendErrors">{{ backendErrors }}</span>
        <span class="small text-danger" v-else-if="errors.length > 0">{{ errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
    export default {
        name: "SelectStateField",
        props: ['field', 'value', 'fieldName'],
        data() {
            return {
                valueVal: '',

                options: {
                    'NSW': 'New South Wales',
                    'QLD': 'Queensland',
                    'SA': 'South Australia',
                    'TAS': 'Tasmania',
                    'VIC': 'Victoria',
                    'WA': 'Western Australia',
                    'ACT': 'Australian Capital Territory',
                    'NT': 'Northern Territory'
                }
            }
        },
        watch: {
            valueVal: function (newValue) {
                this.$emit('update:value', newValue);     
            }
        },
        mounted() {
            this.valueVal = this.value;
        },
        computed: {
            backendErrors () {
                let errors = this.$store.getters["verificationWizard/getErrors"];
                if (errors !== null && errors[this.fieldName] !== undefined){
                    return errors[this.fieldName][0];
                }

                return null;
            }
        }
    }
</script>

<style scoped>

</style>
