const FieldType = {
    TEXT: 'text',
    DATE: 'date',
    DATE_YM: 'date-ym',
    SELECT: 'select',
    SELECT_STATE: 'select-state',
    SELECT_COUNTRY: 'select-country',
    CHECKBOX: 'checkbox',
    YEAR: 'year',
    CARDNO: 'cardNumber'
}

export default FieldType;
