<template>
    <div class="row">
        <div class="col-12">

            <div class="d-flex flex-column justify-content-around text-center h-100">
                <h1 class="mt-4 mb-4">Verify your identity with...</h1>

                <img class="brand--big mt-4 mb-4" src="../assets/img/hrb_logo.png" alt="">

                <div>

                    <button class="home__button-begin mb-4 btn btn-lg btn-outline-primary text-uppercase" @click.prevent="onBeginIdCheckClick">Begin
                        ID Check
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'Home',
        data() {
            return {
                trackingNumber: null
            }
        },
        created() {
            this.trackingNumber = this.$route.query.trackingNumber
        },

        methods: {
            onBeginIdCheckClick() {
                this.$store.dispatch(
                    "verificationWizard/checkToken",
                    this.trackingNumber
                ).then(() => this.$router.push({name: 'Step 1 - Terms and Conditions'}))
                .catch((key) => {
                    let dialog = this.$dialog;
                    if (key === 'notoken') {
                        dialog.alert('Use the link provided to you from H&R Block to begin the ID check.');
                    } else if (key === 'used'){
                        dialog.alert('This token has already been used. Please contact H&R block for new token.')
                    }
                })
            }
        }
    }

</script>

<style lang="scss" scoped>

    .home__button-begin {
        width: 66%;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .brand--big {
        display: block;
        margin: 0 auto;
        width: 66%;
    }
</style>
