<template>
    <ValidationProvider :rules="field.rules" v-slot="{ errors }" :name="field.label">
        <select class="form-control" v-model="valueVal">
            <option value=""></option>
            <option v-for="(option, optionKey) in field.options" v-bind:key="'option-'+optionKey" :value="optionKey">{{ option }}
            </option>
        </select>
        <span class="small text-danger" v-if="backendErrors">{{ backendErrors }}</span>
        <span class="small text-danger" v-else-if="errors.length > 0">{{ errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
    export default {
        name: "SelectField",
        props: ['field', 'value', 'fieldName'],
        data() {
            return {
                valueVal: ''
            }
        },
        watch: {
            valueVal: function (newValue) {
                this.$emit('update:value', newValue);
            }
        },
        mounted() {
            this.valueVal = this.value;
        },
        computed: {
            backendErrors () {
                let errors = this.$store.getters["verificationWizard/getErrors"];
                if (errors !== null && errors[this.fieldName] !== undefined){
                    return errors[this.fieldName][0];
                }

                return null;
            }
        }
    }
</script>

<style scoped>

</style>
