<template>
    <div>
        <ValidationProvider :rules="fieldRules()" v-slot="{ errors }" name="Year">
            <input type="number" class="form-control" v-model="valueVal.year" placeholder="YYYY"/>
            <span class="small text-danger" v-if="backendErrors">{{ backendErrors }}</span>
            <span class="small text-danger" v-else-if="errors.length > 0">{{ errors[0] }}</span>
        </ValidationProvider>
    </div>
</template>

<script>
    import {extend} from 'vee-validate';
    import {min_value, max_value} from 'vee-validate/dist/rules';

    extend('min_value', min_value);
    extend('max_value', max_value);

    export default {
        name: "YearField",
        props: ['field', 'value', 'fieldName'],
        data() {
            return {
                valueVal: {
                    year: '',
                }
            }
        },
        watch: {
            valueVal: {
                deep: true,
                handler() {
                    this.updateValue();
                }
            },
        },
        mounted() {
            if (this.value === undefined) {
                return;
            }
            this.valueVal.year = this.value;
        },
        methods: {
            updateValue: function () {
                let value = this.valueVal.year;
                this.$emit('update:value', value);
            },
            fieldRules: function () {
                let rules = 'min_value:1900|max_value:2100';
                if (this.field.rules !== undefined) {
                    rules += '|' + this.field.rules;
                }

                return rules;
            }
        },
        computed: {
            backendErrors () {
                let errors = this.$store.getters["verificationWizard/getErrors"];
                if (errors !== null && errors[this.fieldName] !== undefined){
                    return errors[this.fieldName][0];
                }

                return null;
            }
        }
    }
</script>

<style scoped>

</style>
