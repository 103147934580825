<template>
    <div class="row">
        <div class="col-12">
            <div class="d-flex flex-column justify-content-around text-center h-100">
                <h1
                    class="mt-4 mb-4 text-danger"
                >Use the link provided to you from H&amp;R Block to begin the ID check</h1>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Error"
};
</script>

<style lang="scss" scoped>
</style>
